
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import CookieService from "@/core/services/CookieService";
import { COOKIE_KEY, OPERATOR, SEARCH_ENTITY } from "@/core/config/Constant";
import { ISearchField } from "@/core/entity/ISearchField";
import { HandlePanel, SearchEntityEvent } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import store from "@/store";
import PolicyCard from "@/views/policy/PolicyViewCard.vue";
import RecentCards from "@/components/base/common/RecentCards.vue";

export default defineComponent( {
  name: "Recent",
  components: {RecentCards,  PolicyCard, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Recent Policies',
        [
          {link: false, router: '', text: 'Recent Policies'}
        ]
      )
    })
    const ids = ref("")
    const recentList =  CookieService.get(COOKIE_KEY.RECENT_POLICIES);
    if (recentList) {
      ids.value = JSON.parse(recentList).join(",")
    }
    const policyField: ISearchField[] = [
      {column: 'policyId', type: 'HIDDEN', operator: OPERATOR.IN, value: ids.value? ids.value : '0', name: ''},
    ]
    const policyViewPage = computed(() => store.state.PolicyModule.policyViewPage)
    const policy = computed(() => store.state.PolicyModule.policy)

    return {
      recentList,
      ...SearchEntityEvent(Actions.POLICY_SEARCH, policyField, ['all'], [], SEARCH_ENTITY.POLICY_VIEW),
      policyViewPage,
      ...HandlePanel(Actions.LOAD_POLICY),
      policy,
    }
  }
})
